<div class="main-wrapper" fxLayout="row" fxLayoutAlign="center center">

  <mat-card class="box">
    <mat-card-header>
      <mat-card-title>Sign In</mat-card-title>
    </mat-card-header>

    <form class="example-form" [formGroup]="form" (submit)="login()">
      <mat-card-content>
        <mat-form-field class="example-full-width">
          <input [disabled]="isLoading" formControlName="username" matInput placeholder="Email" required>
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <input [disabled]="isLoading" formControlName="password" matInput placeholder="Password" type="password" required>
        </mat-form-field>
      </mat-card-content>
      <button mat-stroked-button color="accent" class="btn-block" *ngIf="isLoading === false">Sign-in</button>
      <ngx-skeleton-loader *ngIf="isLoading === true" [theme]="button"></ngx-skeleton-loader>
    </form>
    <div *ngIf="noRedirectUrl === true" style="text-align: center;">
      <h2 style="margin-top: 10px; ">Login successfully!</h2>
    </div>
  </mat-card>
</div>
