import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { DataService } from "src/shared/services";
import { SignInResponse } from "./signin-response.model";
import { SignIn } from "./signin.model";

@Injectable({
  providedIn: 'root'
})
export class SignInService {
  constructor(
    private dataService: DataService
  ) { }

  login(signIn: SignIn): Promise<SignInResponse> {
    return this.dataService.post<SignInResponse>(`${environment.apiUrl}login`, signIn);
  }
}
