import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { DataService } from "src/shared/services";
import { RegisterResponse } from "./register-response.model";
import { Register } from "./register.model";

@Injectable({
  providedIn: 'root'
})
export class RegisterService {
  constructor(
    private dataService: DataService
  ) { }

  register(register: Register): Promise<RegisterResponse> {
    return this.dataService.post<RegisterResponse>(`${environment.apiUrl}register`, register);
  }
}
