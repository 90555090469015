<div class="main-wrapper" fxLayout="row" fxLayoutAlign="center center">
  <mat-card class="box">
    <mat-card-header>
      <mat-card-title>Register</mat-card-title>
    </mat-card-header>

    <form class="example-form" [formGroup]="form" (submit)="register()">

      <mat-card-content>
        <mat-form-field class="example-full-width">
          <input formControlName="email" matInput placeholder="Email" required="">
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <input formControlName="password" matInput placeholder="Password" type="password" required="">
        </mat-form-field>

      </mat-card-content>

      <button mat-stroked-button color="accent" class="btn-block" *ngIf="isLoading === false">Register</button>
      <ngx-skeleton-loader *ngIf="isLoading === true" [theme]="button"></ngx-skeleton-loader>
    </form>
  </mat-card>
</div>
