import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SigninComponent } from './components/signin/signin.component';
import { RegisterComponent } from './components/register/register.component';
import { MaterialModule } from 'src/shared/modules/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertsService, DataService, HashPreserveQueryLocationStrategy } from 'src/shared/services';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule } from '@angular/common/http';
import { LocationStrategy } from '@angular/common';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@NgModule({
  declarations: [
    AppComponent,
    SigninComponent,
    RegisterComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    FlexLayoutModule,
    HttpClientModule,
    MaterialModule,
    NgxSkeletonLoaderModule,
    ReactiveFormsModule,
    ToastrModule.forRoot()
  ],
  providers: [
    AlertsService,
    DataService,
    { provide: LocationStrategy, useClass: HashPreserveQueryLocationStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
