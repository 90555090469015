import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertsService } from 'src/shared/services';
import { SignInService } from './signin.service';
import { SignInResponse } from './signin-response.model';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.less']
})

export class SigninComponent implements OnInit {
  button = { 'height': '56px', width: '100%' };
  isLoading = false;
  form: FormGroup;
  noRedirectUrl = false;

  constructor(
    private alertsService: AlertsService,
    private fb: FormBuilder,
    private signInService: SignInService) {
    this.form = this.fb.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });
  }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.clearTokenInformation();
  }

  login(): void {
    this.isLoading = true;
    let signIn = this.form.value;
    this.signInService.login(signIn)
      .then(resp => this.success(resp))
      .catch((err) => this.alertsService.showError('Error'))
      .finally(() => this.isLoading = false);
  }

  private saveResponse(response: SignInResponse): void {
    localStorage.removeItem('k-auth');
    localStorage.removeItem('k-auth-expiration');
    localStorage.setItem('k-auth', response.token);
    localStorage.setItem('k-auth-expiration', response.expiration);
  }

  private redirect(response: SignInResponse) {
    if (window.location.search !== '') {
      const params = new URLSearchParams(window.location.search);
      let redirectUrl = params.get("redirectUrl");
      if (redirectUrl) {
        if (redirectUrl.split('?').length > 1) {
          redirectUrl = `${redirectUrl}&`;
        } else {
          redirectUrl = `${redirectUrl}?`;
        }
        redirectUrl = `${redirectUrl}ticket=${response.token}`;
        window.location.href = redirectUrl;
      } else {
        this.noRedirectUrl = true;
      }
    } else {
      this.noRedirectUrl = true;
    }
  }

  private success(response: SignInResponse): void {
    this.saveResponse(response);
    this.alertsService.showSuccess();
    this.redirect(response);
  }

  private clearTokenInformation(): void {
    if (localStorage.getItem('k-auth') !== null || localStorage.getItem('k-auth-expiration') !== null) {
      localStorage.setItem('k-auth', 'undefined');
      localStorage.setItem('k-auth-expiration', 'undefined');
      localStorage.removeItem('k-auth');
      localStorage.removeItem('k-auth-expiration');
    }
  }
}
