import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'; 
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class DataService {
    constructor(private http: HttpClient) {
    }

    get<T>(url: string): Promise<T> {
        return this.http.get<T>(url, { headers: this.getHeaders() })
            .pipe(
                catchError((e) => this.handleError(e)),
                map((data: T) => data)
            ).toPromise();
    }

    post<T>(url: string, data: any): Promise<T> {
        return this.http.post<T>(url, data, { headers: this.getHeaders() })
            .pipe(
                catchError(this.handleError),
                map((error: T) => error)
            ).toPromise();
    }

    put<T>(url: string, data: any): Promise<T> {
        return this.http.put<T>(url, data, { headers: this.getHeaders() })
            .pipe(
                catchError(this.handleError),
                map((error: T) => error)
            ).toPromise();          
    }

    delete<T>(url: string): Promise<T> {
        return this.http.delete<T>(url, { headers: this.getHeaders() })
            .pipe(
                catchError(this.handleError),
                map((error: T) => error)
            ).toPromise();
    }

    private getHeaders(): HttpHeaders {
        // TODO: Add Auth header if a user is logged in for API requests
        const accessToken = '';
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', `Bearer ${accessToken}`);

        return headers;
    }

    private handleError(e: any) {
        console.log('Error', e);
        return throwError(new Error(e?.error || e));
    }
}
