import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertsService } from 'src/shared/services';
import { RegisterService } from './register.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.less']
})

export class RegisterComponent implements OnInit {
  button = { 'height': '56px', width: '100%' };
  form: FormGroup;
  isLoading = false;

  constructor(
    private alertsService: AlertsService,
    private fb: FormBuilder,
    private registerService: RegisterService,
    private router: Router) {
    this.form = this.fb.group({
      email: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });
  }

  ngOnInit(): void { }

  register() {
    this.isLoading = true;
    let register = this.form.value;
    this.registerService.register(register)
    .then(() => this.success())
    .catch((err) => this.alertsService.showError(err))
    .finally(() => this.isLoading = false);
  }

  private success() {
    this.alertsService.showSuccess();
    this.router.navigate(['signin']);
  }
}
